@media screen and (max-width: 830px) {
  
  #header h1 {
    font-size:65px;
    margin: 0 2.4rem;
  }
}



@media screen and (max-width: 640px) {

  #header h1 {
    font-size: 55px;
    margin: 0 2.4rem;
  }

  #header p {
    font-size: 20px;
    margin: 0 2.4rem;
  }
}

@media screen and (max-width: 550px) {
  #header h1 {
    font-size: 45px;
    margin: 0 2.4rem;
  }

  #header p {
    font-size: 20px;
    margin: 0 2.4rem;
  }
  #impressum {
    width: 100%;
  }

  #impressum h2 {
    font-size: 22px;
  }

  #impressum h3 {
    font-size: 18px;
  }

  #impressum h4 {
    font-size: 15px;
  }

  #impressum p {
    font-size: 10px;
  }
  .captcha{
    width: 50px;
  }
}

@media screen and (max-width: 470px) {

  #header h1 {
    font-size: 40px;
    margin: 0 2.4rem;
  }

  #header p {
    font-size: 15px;
    margin: 0 2.4rem;
  }

  #services img {
    width: 300px;
  }

  #services h2 {
    font-size: 25px;
  }

  th{
    font-size: 10px;
  }


}

@media screen and (max-width: 440px) {

  #header h1 {
    font-size: 30px;
    margin: 0 2.4rem;
  }

  #header p {
    font-size: 15px;
    margin: 0 2.4rem;
    -webkit-text-stroke: 0px rgba(0,31,63,255);
  }

  td{
    font-size: small;
  }

  #services img {
    width: 300px;
  }

  #services h2 {
    font-size: 25px;
  }
}

@media screen and (max-width: 360px) {

  #header h1 {
    font-size: 30px;
    margin: 0 2.4rem;
  }

  th{
    font-size: 8px;
  }
  .btn-custom {
    padding: 14px 15px;
  }
}
